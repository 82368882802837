button {
    border: none;
    outline: none;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    transition: background-color 300ms, color 300ms;
    font-size: 1rem;
    .btn-icon {
        transition: transform 300ms;
        margin-left: 0.5rem;
    }
    &:hover .btn-icon {
        transform: translateX(0.25rem);
    }
    &:disabled {
        background-color: #a3a3a3;
        &:hover {
            background-color: #a3a3a3;
        }
    }
}

.btn-primary {
    background-color: var(--main-blue);
    color: var(--background-white);
    padding: 0.7rem 1rem;
    &:hover {
        background-color: var(--main-blue-dark);
    }
}

.btn-primary-reversed {
    color: var(--main-blue);
    background-color: var(--background-white);
    padding: 0.7rem 1rem;
    &:hover {
        background-color: darken($color: #FBFBFB, $amount: 15);
    }
}

.btn-secondary {
    background-color: var(--background-white);
    color: var(--main-blue);
    padding: calc(0.7rem - 3px) calc(1rem - 3px);
    border: 3px solid var(--main-blue);
    &:hover {
        background-color: var(--main-blue);
        color: white;
    }
}

.btn-secondary-reversed {
    color: var(--background-white);
    background-color: var(--main-blue);
    padding: calc(0.7rem - 3px) calc(1rem - 3px);
    border: 3px solid var(--background-white);
    &:hover {
        color: var(--main-blue);
        background-color: white;
    }
}

.social-btns {
    display: flex;
    font-size: 1.8rem;
}

// CTAs
.cta {
    .btn-primary, .btn-secondary {
        margin-top: 0.5rem;
    }
    span {
        font-size: 0.8rem;
    }
}

.ctas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto 2rem;
}

.primary-cta {
    margin-bottom: 1rem;
}

// Links
a {
    text-decoration: none;
    color: var(--text-blue);
    transition: color 300ms;
}

footer a {
    color: rgba(255, 255, 255, .8);
    padding: 0.5rem;
    display: block;
    &:hover {
        color: white;
    }
}

.text-link {
    color: var(--main-blue);
}

// Icons
.icon {
    width: 5rem;
    height: 5rem;
    font-size: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1.5rem;
    border-radius: 50%;
    background-color: var(--main-blue);
    background: linear-gradient(144deg, rgba(0, 123, 186, 1) 50%, rgba(124, 205, 244, 1) 100%);
    color: white;
}

@media only screen and (min-width: 350px) {
    .ctas {
        flex-direction: row;
        gap: 2rem;
    }
}