@use './base/shared';
@use './base/animations';
@use './components/buttons';
@use './components/cards';
@use './components/navbar';
@use './components/footer';
@use './sections/hero';
@use './sections/security';
@use './sections/features';
@use './sections/roadmap';
@use './sections/pricing';
@use './sections/faqs';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

:root {
    --text-blue: #23233B;
    --main-blue-dark: #2C4268;
    --main-blue: #007BBA;
    --main-blue-light: #00A9E2;
    --secondary-blue: #7CCDF4;
    --secondary-blue-light: #BCE3FA;
    --background-white: #FBFBFB;
    --red: #f44336;
}

html {
    font-size: 16px;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: var(--text-blue);
    background-color: var(--background-white);
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0.6rem;
    }
    &::-webkit-scrollbar-track {
        background-color: var(--main-blue-dark);
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--secondary-blue);
    }
}