.pricing {
    position: relative;
    text-align: center;
    .price {
        margin: 1rem 0 0;
        font-size: 3rem;
        font-weight: 400;
        sup {
            font-size: 1.5rem;
        }
        span {
            font-size: 1.25rem;
        }
    }
    .price-label {
        font-size: 1.25rem;
        margin: 0;
    }
    .price-cards {
        display: grid;
        gap: 1rem;
        margin: 2rem 0;
    }
    .plan-benefits {
        text-align: left;
        margin-top: 1rem;
        li {
            margin-bottom: 0.5rem;
        }
        i {
            color: #00A483;
        }
    }
    .price-actions {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .decoration {
        position: absolute;
        background-color: var(--main-blue);
        bottom: 0;
        left: 0;
        width: 100%;
        height: 35vh;
        z-index: -1;
    }
}

@media only screen and (min-width: 725px) {
    .pricing .price-cards {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
}

@media only screen and (min-width: 1000px) {
    .pricing .price-cards {
        max-width: 950px;
        margin: 2rem auto;
        .price-actions {
            grid-template-columns: 1fr 1fr;
        }
    }
}