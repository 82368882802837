.features {
    text-align: center;
}

.features-grid {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin: 2rem auto;
    max-width: 1000px;
    .feature {
        opacity: 0;
        transform: translateY(50px);
        .feature-img {
            height: 210px;
            object-fit: cover;
        }
        .mobile-img {
            height: unset;
        }
    }
}

.app-features {
    background-color: #eff3f9;
    .section-title {
        margin-bottom: 3rem;
    }
}

.app-features-grid {
    display: grid;
    gap: 2rem 1rem;
}

.app-feature {
    display: flex;
    flex-direction: column;
    .feature-text {
        padding: 0 1rem;
    }
    i {
        color: #00A483;
    }
}

@media only screen and (min-width: 725px) {
    .app-features-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 1000px) {
    .app-features .section-title {
        text-align: center;
    }
}

@media only screen and (min-width: 1250px) {
    .app-feature .feature-text {
        padding: 0 1.5rem;
    }
}