.hero {
    text-align: center;
    padding-top: 1rem !important;
    .section-image img {
        max-width: 500px;
    }
}

.hero-title {
    margin: 1rem 0 0.5rem;
    font-weight: 500;
    font-size: 2rem;
}

.hero-subtitle {
    margin: 0 0 2rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4;
}

.hero-anim,
.hero-img {
    opacity: 0;
}

.hero-anim {
    transform: translateY(-50px);
}

.secondary-hero .hero-img {
    transform: translateX(50px);
}

.secondary-hero {
    display: grid;
    gap: 1rem;
    .hero-title,
    .hero-subtitle {
        width: 100%;
    }
    .hero-title {
        margin-top: 0;
    }
    .section-image img {
        max-width: 650px;
    }
    .placeholder-img img {
        max-width: 590px;
    }
}

@media only screen and (min-width: 725px) {
    .hero-title,
    .hero-subtitle {
        width: 80%;
    }
    .hero-title {
        margin: 2rem auto 0.5rem;
    }
    .hero-subtitle {
        margin: 0 auto 2rem;
        font-size: 1.15rem;
    }
    .secondary-hero {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'text image';
        gap: 2rem;
        .text {
            grid-area: text;
        }
        .section-image,
        .placeholder-img {
            grid-area: image;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .hero-title,
    .hero-subtitle {
        width: 70%;
    }
    .secondary-hero {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 'text image image';
    }
}