.security {
    display: flex;
    flex-direction: column;
    .section-subtitle {
        margin-bottom: 2rem;
    }
    .illustration {
        margin-bottom: 1rem;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
        img {
            max-width: 400px;
        }
    }
}

.extras {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
    .extra {
        opacity: 0;
        transform: translateY(50px);
    }
}

@media only screen and (min-width: 1000px) {
    .security {
        flex-direction: row;
        align-items: center;
        .illustration {
            margin-bottom: 0;
            margin-left: 1rem;
        }
    }
    .extras {
        gap: 2rem;
    }
}