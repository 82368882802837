.faqs {
    background-color: var(--main-blue);
}

.faq {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
    color: white;
    .question {
        display: flex;
        .fas {
            margin-right: 1.5rem;
            font-size: 2rem;
        }
        .text-link {
            color: white;
            text-decoration: underline;
        }
    }
}