footer {
    background-color: var(--main-blue);
    color: white;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .copy,
    h4 {
        text-transform: uppercase;
    }
    h4 {
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
    h3,
    h4 {
        padding: 0 0.5rem;
    }
}