ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul,
ol {
    li {
        line-height: 1.6;
    }
}

.header-nav,
section,
footer {
    padding-right: 1rem;
    padding-left: 1rem;
}

section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.section-title {
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
}

.section-subtitle,
p {
    line-height: 1.6;
    margin-top: 0;
}

p .strong {
    font-weight: 600;
}

.tabbed {
    margin-left: 2rem;
}

h3 {
    font-weight: 700;
    font-size: 1rem;
    margin: 0 0 0.5rem;
}

.section-image,
.placeholder-img,
.illustration,
.app-feature,
.feature {
    img {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}

.app-feature,
.feature {
    .feature-img {
        margin-bottom: 1rem;
    }
}

@media only screen and (min-width: 725px) {
    .header-nav,
    section,
    footer {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    section {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .extras,
    .faq {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media only screen and (min-width: 1000px) {
    .header-nav,
    section,
    footer {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    section {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}