.card {
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    background-color: white;
    p {
        margin-bottom: 0;
    }
}

.lift {
    transition: transform 300ms, box-shadow 300ms;
    position: relative;
    p {
        text-align: left;
    }
    .icon {
        width: 3rem;
        height: 3rem;
        font-size: 1.3rem;
    }
    &:hover {
        transform: translateY(-0.33rem);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 10px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    }
}

.pill {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    background-color: #eff3f9;
    text-transform: uppercase;
    letter-spacing: 1px;
}


@media only screen and (min-width: 550px) {
    .card {
        padding: 3rem;
    }
}