.header-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 1rem;
}

.brand {
    display: flex;
    align-items: center;
    .logo-img {
        max-height: 3.125rem;
        margin-right: 0.6rem;
    }
    span {
        color: var(--main-blue);
        font-size: 1.5rem;
        letter-spacing: 1px;
    }
}

.nav-list {
    display: flex;
    margin-top: 0.5rem;
    .nav-link {
        display: block;
        padding: 1rem 0.5rem;
        text-align: center;
        &.active,
        &:hover {
            color: var(--main-blue);
        }
    }
}

@media only screen and (min-width: 350px) {
    .nav-list .nav-link {
        padding: 1rem;
    }
}

@media only screen and (min-width: 725px) {
    .header-nav {
        flex-flow: row;
        justify-content: space-between;
        padding: 1rem 2rem;
    }
    .nav-list {
        margin-top: 0;
    }
    .brand {
        margin-left: 1rem;
    }
}

@media only screen and (min-width: 1000px) {
    .header-nav {
        padding: 1rem 5rem;
    }
}