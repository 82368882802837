.roadmap {
    text-align: center;
    .progress-outer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 2rem;
        margin: 3rem 0;
        border-radius: 1rem;
        background-color: #e7e9ee;
    }
    .progress-inner {
        position: absolute;
        height: 1.5rem;
        top: 0.25rem;
        left: 0.25rem;
        border-radius: 1rem;
        background-color: var(--main-blue);
        transition: width 300ms;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: linear-gradient( -45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
            z-index: 1;
            background-size: 50px 50px;
            animation: move 2s linear infinite;
        }
    }
    .marker {
        position: relative;
        z-index: 2;
        .marker-dot {
            display: block;
            width: 2rem;
            height: 2rem;
            background-color: #a3a3a3;
            border-radius: 50%;
        }
        .completed {
            background-color: var(--secondary-blue);
        }
        .marker-text {
            position: absolute;
            bottom: -50px;
            left: -85%;
            width: 90px;
            text-align: center;
            font-size: 0.7rem;
        }
    }
}